export const STRIPE_PRE_PAY = 'cdb/payment/stripe/prepay'
export const STRIPE_RECHARGE = 'cdb/payment/stripe/recharge'
export const STRIPE_PAY_OF_CONFIRM = 'cdb/payment/stripe/prepayOfConfirm'
export const STRIPE_RECHARGE_OF_CONFIRM = 'cdb/payment/stripe/rechargeOfConfirm'
export const STRIPE_PAY_CONFIRM = 'cdb/payment/stripe/payConfirm'
export const STRIPE_PREAUTH_OF_CONFIRM = 'cdb/payment/stripe/multicurrency/preauthOfIntent/payment' // 信用卡预授权租借
export const STRIPE_TOURIST_PREAUTH = 'cdb/payment/stripe/multicurrency/touristCreatePreAuth' // 游客预授权租借
export const STRIPE_PREAUTH_AUTO_CONFIRM = 'cdb/payment/stripe/multicurrency/confirm' // 信用卡确认订单
export const STRIPE_PREPAY_SPTOKEN = 'cdb/payment/stripe/multicurrency/preAuth/stripToken' // 目前使用的预授权租借
export const STRIPE_SAVE_CARD = 'cdb/payment/stripe/multicurrency/saveCardOfIntent' // stripe保存卡
export const STRIPE_SAVE_CARD_CONFIRM = 'cdb/payment/stripe/multicurrency/saveCardConfirm' // stripe保存卡确认
export const SAVED_CARD_RENT = 'cdb/payment/stripe/multicurrency/preauthByCard/payment' // 已保存卡租借
export const COUPON_SEND = 'cdb/mine/coupon/send' // 换取优惠券

export const PAYPAL_PRE_PAY = 'cdb/payment/paypal/prepay'
export const PAYPAL_RECHARGE = 'cdb/payment/paypal/recharge'
export const PAYPAL_EXC_PAY = 'cdb/payment/paypal/excpay'

export const WECHAT_MP_PRE_PAY = 'cdb/payment/wechat_mp/prepay'
export const ALIPAY_PRE_PAY = 'cdb/payment/alipay/prepay'

export const WECHAT_MP_RECHARGE = 'cdb/payment/wechat_mp/recharge'

export const YEDPAY_RECHARGE = 'cdb/payment/yedpay/recharge'
export const YEDPAY_PREPAY = 'cdb/payment/yedpay/prepay'
export const YEDPAY_PAY_CONFIRM = 'cdb/payment/yedpay/payConfirm'
export const YEDPAY_PREPAY_RENT = 'cdb/payment/yedpay/rent'
export const YEDPAY_PAY_ORDER = 'cdb/payment/yedpay/payOrder'
export const VIP_ORDER = 'cdb/payment/yedpay/vipOrder' // yedpay购买vip
export const YEDPAY_PAY_COUPON = 'cdb/payment/yedpay/buyCoupon'

export const TIXIAN_YAJIN = 'cdb/mine/tixian/yajin'
export const TIXIAN_APPLY = 'cdb/mine/yajin/apply'
export const CREATE_VIP_ORDER = 'cdb/cabinet/createorderByVip'
export const CDY_REMART_HOME = 'cdb/payment/cdymart/home'
// eft 支付
export const EFTPAY_COUPON = 'cdb/payment/eftPay/buyCoupon' // eft购买充电券
export const EFTPAY_VIP_ORDER = 'cdb/payment/eftPay/vipOrder' // eft购买vip
export const EFT_PREPAY_RENT = 'cdb/payment/eftPay/rent' // eft押金租借
export const EFT_PREPAY = 'cdb/payment/eftPay/prepay' // eft充值押金、租借
export const EFT_PAY_ORDER = 'cdb/payment/eftPay/payOrder' // eft订单付款
export const EFT_PAY_CONFIRM = 'cdb/payment/eftPay/payConfirm' // eft确认订单是否付款
// 丹麦支付
export const SAVE_CARD = 'cdb/payment/quickpay/multiCurrency/saveCard' // 保存卡
export const QUICK_CREATE = 'cdb/payment/quickpay/multiCurrency/createOrder' // 创建预授权订单
export const WALLET_RECHARGE = 'cdb/payment/quickpay/multiCurrency/recharge' // 钱包充值
export const PAY_ORDER = 'cdb/payment/quickpay/multiCurrency/payOrder' // 订单付款
export const DEPOSIT_TO_RENT = 'cdb/cabinet/useDepositToRent' // 押金租借
export const BALANCE_TO_RENT = 'cdb/cabinet/useBalanceToRent' // 用户余额抵扣押金并租借
export const SAVEDCARD_CONFIRM = 'cdb/payment/quickpay/multiCurrency/savedCard/confirm' // 确认保存卡是否已授权
export const QUICKPAY_CONFIRM = 'cdb/payment/quickpay/multiCurrency/payment/confirm' // 确认订单是否授权或支付

// RedsysPay 支付
export const REDSYS_PREAUTHORDER = 'cdb/payment/redsys/preAuthOrder' // 创建预授权订单
export const REDSYS_RECHARGE = 'cdb/payment/redsys/recharge' // 钱包充值
export const REDSYS_PAYORDER = 'cdb/payment/redsys/payOrder' // 订单付款
export const REDSYS_PAY_CONFIRM = 'cdb/payment/redsys/payment/confirm/' // 确认订单是否授权或支付 {outTradeNo}

export const USER_CARD = 'cdb/user/card' // 卡列表
export const WALLET_LIST = 'cdb/user/wallet' // 钱包列表
export const REMOVE_CARD = 'cdb/user/card/remove' // 删除卡
export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
