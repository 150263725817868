<template>
  <div class="page" :style="{ 'padding-top': safeArea.top + 'px' }">
    <div class="page-nav">
      <slot name="nav"> </slot>
    </div>
    <div class="page-head">
      <slot name="head">
        <img
          v-if="back"
          class="page-head-back"
          src="../../assets/imgs/back.png"
          alt=""
          @click="onBack"
        />
        <!-- <img class="logo" src="../../assets/imgs/head_logo.png" alt="" /> -->
        <div class="righticon">
          <img
            v-if="help"
            src="../../assets/imgs/icon_help_light.png"
            alt=""
            @click="goRouter('/help')"
          />
        </div>
      </slot>
    </div>
    <div class="page-body">
      <slot></slot>
    </div>
    <div class="page-foot" v-if="hasFoot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    showBack: {
      type: Boolean,
      default: true
    },
    back: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Boolean,
      default: true
    },
    help: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["safeArea", "headLogo"]),
    hasHead() {
      return this.$slots.head || this.title
    },
    hasFoot() {
      return this.$slots.foot
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    onBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less">
.page {
  &-head {
    // position: fixed;
    // z-index: 99;
    // top: 0;
    width: 100%;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 20px 0 0;
    padding: 20px 0;
    .righticon {
      width: 60px;
      margin-right: 32px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .menu {
      width: 60px;
      margin-right: 32px;
    }
    &-back {
      width: 60px;
      margin-left: 32px;
    }
    .logo {
      height: 48px;
      background: #62b22f;
      padding: 10px;
      border-radius: 20px;
    }
  }
  // &-head+&-body{
  //   padding-top: 88px;
  // }
  &-body {
    height: 100%;
    min-height: 100%;
  }
  &-foot {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
.page-head-default .home {
  height: 40px;
  width: 40px;
  // position: absolute;
  left: calc(100vw - 72px);
  right: 32px;
  transform: scale(1.4);
  position: fixed;
}
</style>
