<template>
  <page headColor="#fff">
    <template slot="head">
      <img
        class="page-head-back"
        src="../../assets/imgs/icon_back_color.png"
        alt=""
        @click="onBack"
      />
      <img class="logo" :src="headLogo && headLogo.blackLogoUrl" alt="" />
      <div class="righticon"></div>
    </template>
    <div class="wallet-balance">
      <div class="wallet-balance-tabs">
        <span>{{ i18n.balance }}</span>
        <span class="withdrawal" @click="goRouter('/wallet_out')">{{
          i18n.wallet_out
        }}</span>
      </div>
      <h1>
        {{ walletList[0] ? walletList[0].pCurrencySymbol : "Dkr"
        }}{{ walletList[0] ? walletList[0].pBalance : 0 }}
      </h1>
      <span class="wallet-balance-deposit"
        >{{ i18n.deposit }}:{{
          walletList[0] ? walletList[0].pDepositAmount : user.pyajin || 0
        }}</span
      >
      <!-- <span class="wallet-balance-deposit">{{i18n.deposit}}:{{item.pDepositAmount || 0}}</span> -->
    </div>
    <div class="wallet-card">
      <p class="wallet-card-title">{{ i18n.mywalletcard }}</p>
      <div class="addcard" @click="saveCard()">
        <div class="left">
          <img src="../../assets/imgs/icon_coupon.png" alt="" />
          <span class="addcard-msg">{{ i18n.addcard }}</span>
        </div>
        <img src="../../assets/imgs/icon_more.png" alt="" />
      </div>
    </div>
    <div class="scroll-y">
      <div
        class="card-list"
        v-for="item in cardList"
        :key="item.pId"
        @click="payMode = item"
      >
        <p class="value">
          <span class="pBrand">{{ item.pBrand }}</span>
          <span>****{{ item.pCardnumber }}</span>
        </p>
        <p class="date">{{ item.pExpirationdate }}</p>
      </div>
    </div>
    <span class="btn wallet-btn" @click="goRouter('/wallet_in')">{{
      i18n.wallet_in
    }}</span>
    <!-- <div v-if="paymethodshow" class="paymethod-mask" @click="paymethodshow = false">
      <div class="paymethod-list">
        <p class="title">请选择绑定的支付渠道</p>
        <div class="paymethod-list-item" v-for="item in payMethodList" :key="item.pId" @click="saveCard(item)">
          <span class="paymethod-name">{{ item.pPaymentType }}</span>
          <span class="paymethod-currency">{{ item.currencySymbol }}</span>
        </div>
      </div>
    </div> -->
  </page>
</template>

<script>
import { USER_INFO } from "../../apis/user.js"
import {
  USER_CARD,
  SAVE_CARD,
  WALLET_LIST,
  PAYMODE
} from "../../apis/payment.js"
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user", "headLogo", "agentId"]),
    i18n() {
      return this.$t("mine")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  data() {
    return {
      paymethodshow: false,
      payMethodList: [],
      cardList: [],
      walletList: [],
      payMode: {
        pId: 0
      }
    }
  },
  methods: {
    onBack() {
      this.$router.push("/")
    },
    getCardList() {
      this.$loading(true)
      this.$get(
        USER_CARD,
        {},
        (resp) => {
          this.$loading(false)
          console.log("cardlist", resp)
          this.cardList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getCardList()
          })
          console.log(error)
        }
      )
    },
    getWalletist() {
      this.$loading(true)
      this.$get(
        WALLET_LIST,
        {
          agentId: this.agentId
        },
        (resp) => {
          this.$loading(false)
          console.log("Walletist", resp)
          this.walletList = resp.data
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletist()
          })
          console.log(error)
        }
      )
    },
    saveCard(method) {
      // this.paymethodshow = false
      this.$loading(true)
      this.$post(
        SAVE_CARD,
        {
          paymentId: "2",
          continueUrl: window.location.href,
          cancelUrl: window.location.href
        },
        (resp) => {
          this.$loading(false)
          console.log("savecard", resp)
          window.location.href = resp.data.redirectUrl
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    getPayMethodList() {
      this.$get(
        PAYMODE,
        {},
        (resp) => {
          console.log("PayMethodlist", resp)
          this.payMethodList = resp.data
        },
        (error) => {
          console.log(error)
        }
      )
    },
    loadUser() {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.loadUser()
          })
          console.log(error)
        }
      )
    },
    goRouter(path) {
      this.$router.push(path)
    }
  },
  created() {
    this.loadUser()
    this.getCardList()
    this.getWalletist()
    // this.getPayMethodList()
  }
}
</script>

<style lang="less">
.wallet-balance {
  position: relative;
  margin: 40px auto;
  width: 647px;
  height: 378px;
  background: url("./../../assets/imgs/img_walletbg.png") no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &-tabs {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 34px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      height: 100%;
    }
    .withdrawal {
      color: #00904a;
      border: solid 1px #00904a;
      border-radius: 100px;
    }
  }
  h1 {
    font-size: 76px;
  }
  &-deposit {
    margin: 0 0 20px 0;
  }
}
.wallet-card {
  margin-top: 40px;
  padding: 0 50px;
  &-title {
    color: #999;
    font-size: 34px;
    margin-bottom: 20px;
  }
  .addcard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
}
.scroll-y {
  max-height: 540px;
  margin: 20px 0 40px;
  overflow-y: scroll;
}
.card-list {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px 20px;
  margin: 20px 50px;
  p {
    margin: 15px 0;
  }
  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00904a;
    font-weight: 500;
    .pBrand {
      font-size: 40px;
    }
  }
  .date {
    color: #999;
    text-align: end;
  }
}
.wallet-btn {
  // position: absolute;
  // bottom: 15%;
  // left: 50%;
  // transform: translateX(-50%);
  font-weight: 500;
  width: 85%;
  margin: 0 auto;
}
.paymethod-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  .paymethod-list {
    background: #fff;
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    .title {
      text-align: center;
      font-size: 30px;
      background: #00904a;
      color: #fff;
      margin: 0;
      padding: 10px 0;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px;
      box-shadow: 3px 6px 10px #e8e8e8;
      margin: 20px;
      padding: 30px 20px;
      .paymethod-name {
        font-size: 32px;
      }
      .paymethod-currency {
        color: #999;
      }
    }
  }
}
</style>
