<template>
  <page back>
    <div class="mainbgc"></div>
    <div class="join-warp">
      <div class="join-cont">
        <div class="join-title">Godik</div>
        <div class="join-tip">{{i18n.tips}}</div>
        <div class="join-btn-group">
          <div class="btn-bg" :style="activeClass"></div>
          <div class="join-btn" :class="{'active': isActive === 0}" @click="handleActive(0)">
            <div class="text">{{i18n.type1}}</div>
          </div>
          <div class="join-btn" :class="{'active': isActive === 1}" @click="handleActive(1)">
            <div class="text">{{i18n.type2}}</div>
          </div>
          <div class="join-btn" :class="{'active': isActive === 2}" @click="handleActive(2)">
            <div class="text">{{i18n.type3}}</div>
          </div>
        </div>
        <div class="join-input">
          <input type="text" v-model="info.content" :placeholder="i18n.shop_name"/>
          <input type="text" v-model="info.username" :placeholder="i18n.contact_person" />
          <input type="number" v-model="info.phone" :placeholder="i18n.phone" />
        </div>
        <div class="join-submit" @click="submitFeedback">{{i18n.submit}}</div>
      </div>
    </div>
  </page>
</template>

<script>
import { FEEDBACK } from '../../apis/user.js'
export default {
  computed: {
    i18n () {
      return this.$t('join')
    }
  },
  watch: {
    isActive (val, oldVal) {
      this.activeClass = `left: ${val * this.unit}px;`
    }
  },
  data () {
    return {
      isActive: 0,
      info: {
        type: this.$t('join.type1'),
        content: '',
        username: '',
        phone: ''
      },
      typeAll: {
        0: this.$t('join.type1'),
        1: this.$t('join.type2'),
        2: this.$t('join.type3')
      },
      activeClass: 'left: 0;',
      unit: 100
    }
  },
  methods: {
    handleActive (val) {
      this.isActive = val
      this.info.type = this.typeAll[val]
    },
    submitFeedback () {
      if (!this.info.content) {
        this.$toast(this.i18n.shop_name + ' ' + this.i18n.noempty)
        return
      }
      if (!this.info.username) {
        this.$toast(this.i18n.contact_person + ' ' + this.i18n.noempty)
        return
      }
      if (!this.info.phone) {
        this.$toast(this.i18n.phone + ' ' + this.i18n.noempty)
        return
      }
      this.$loading(true)
      this.$post(
        FEEDBACK,
        {
          type: this.info.type,
          content: this.info.content,
          username: this.info.username,
          phone: this.info.phone,
          // orderId: '',
          cabinetId: '',
          shopId: ''
          // jingdu: '',
          // weidu: '',
          // photos: ''
        },
        resp => {
          this.$loading(false)
          console.log('feedback', resp)
          this.isActive = 0
          this.info = {
            type: this.$t('join.type1'),
            content: '',
            username: '',
            phone: ''
          }
          this.$confirm({
            title: this.$t('msg.title'),
            content: this.$t('msg.success')
          })
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          header: {
            'Content-Type': 'application/json'
          }
        }
      )
    }
  }
}
</script>

<style lang="less">
.join-warp {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #FFFFFF;
  // background-image: linear-gradient(to bottom right, #87CEFA, #FFFFFF);
  color: #666666;
  font-size: 32px;
}
.join-cont {
  width: 660px;
  margin: 0 auto;
  .join-title {
    color: #000;
    font-size: 72px;
    padding: 40px 0;
  }
  .join-tip {
    color: #666666;
    padding-bottom: 40px;
  }
  .join-btn-group {
    display: flex;
    justify-content: space-between;
    line-height: 80px;
    text-align: center;
    background: rgba(249,249,249,1);
    position: relative;
    border-radius: 40px;
    .btn-bg {
      position: absolute;
      z-index: 999;
      left: 0;
      width: 220px;
      height: 80px;
      border-radius: 40px;
      background: #00904A;
      box-shadow: 0px 6px 8px 0px rgba(52,125,255,0.32);
      transition: left 0.2s ease-in;
    }
    .join-btn {
      width: 220px;
      height: 80px;
      z-index: 9999;
    }
    .text {
      height: 80px;
      line-height: 80px;
      font-size: 28px ;
      font-family: PingFangSC-Medium;
      font-weight: 400;
      color: rgba(51,51,51,1);
      animation: join-text-active 0.3s;
      cursor: pointer;
      transition: color 0.3s ease-in;
    }
    .active {
      .text {
        font-weight: 500;
        color: rgba(255,255,255,1);
      }
    }
  }
  .join-input {
    input {
      width: 620px;
      height: 80px;
      background-color: #FFFFFF;
      border: none;
      outline: none;
      border-radius: 8px;
      box-shadow: 0px 8px 24px 0px rgba(53, 53, 53, 0.315);
      margin-top: 60px;
      padding: 0 20px;
      // text-align: center;
    }
  }
  .join-submit {
    line-height: 100px;
    height: 100px;
    text-align: center;
    font-size: 36px;
    background-color: #00904A;
    color: #FFFFFF;
    margin-top: 160px;
    border-radius: 50px;
    box-shadow: 0px 8px 24px 0px rgba(52,125,255,0.32);
  }
}
</style>
