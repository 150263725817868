<template>
  <page back help>
    <div class="mainbgc"></div>
    <div class="home-body">
      <img v-if="!user" class="userAvatar" src="../../assets/imgs/avatar.png" alt="">
      <img v-else-if="user && !user.userAvatar" class="userAvatar" src="../../assets/imgs/avatar_default.png" alt="">
      <img v-else class="userAvatar" :src="user.userAvatar" alt="">
      <h2 v-if="!user || !user.pname">{{i18n.username}}</h2>
      <h2 v-else>{{ user.pname | decode }}</h2>
      <span v-if="!user" class="btn loginbtn" @click="goRouter('/login')">{{ i18n.login }}</span>
      <div class="userinfo" v-else>
        <span class="userinfoitem">ID：{{ user.pid }}</span>
        <span class="userinfoitem">{{ i18n.mobile }}: {{ user.pname }}</span>
      </div>
      <div class="menulist">
        <div class="menulist-item" @click="goRouter('/order')">
          <div class="left">
            <img src="../../assets/imgs/icon_rentorder.png" alt="">
            <span class="menulist-item-title">{{ i18n.orders }}</span>
          </div>
          <img src="../../assets/imgs/icon_more.png" alt="">
        </div>
        <div class="menulist-item" @click="goRouter('/wallet')">
          <div class="left">
            <img src="../../assets/imgs/icon_wallet.png" alt="">
            <span class="menulist-item-title">{{ i18n.wallet }}</span>
          </div>
          <img src="../../assets/imgs/icon_more.png" alt="">
        </div>
        <div class="menulist-item" @click="goRouter('/join')">
          <div class="left">
            <img src="../../assets/imgs/icon_cooperate.png" alt="">
            <span class="menulist-item-title">{{ i18n.joinus }}</span>
          </div>
          <img src="../../assets/imgs/icon_more.png" alt="">
        </div>
        <div class="menulist-item" @click="goRouter('/setting')">
          <div class="left">
            <img class="iconfilter" src="../../assets/imgs/icon_settings.png" alt="">
            <span class="menulist-item-title">{{ i18n.langsetting }}</span>
          </div>
          <img src="../../assets/imgs/icon_more.png" alt="">
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapState([
      'qrcode',
      'zhcode',
      'cabinet',
      'browser',
      'user',
      'system'
    ]),
    i18n () {
      return this.$t('users')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  data () {
    return {
      isScan: false,
      menuList: [{
        id: 1,
        iconPath: '../../assets/imgs/icon_rentorder.png',
        title: 'rental orders'
      }, {
        id: 2,
        iconPath: '../../assets/imgs/icon_coupon.png',
        title: 'My Coupons'
      }, {
        id: 3,
        iconPath: '../../assets/imgs/icon_wallet.png',
        title: 'My Wallet'
      }, {
        id: 4,
        iconPath: '../../assets/imgs/icon_cooperate.png',
        title: 'Cooperation to join'
      }]
    }
  },
  methods: {
    goRouter (path) {
      this.$router.push(path)
    },
    // 頭部主頁圖標按鈕
    goHome () {
      this.$store.commit('qrcode', '')
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>

.home-body{
  position: absolute;
  background: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  overflow: visible;
  .userAvatar{
    width: 172px;
    height: 172px;
    position: absolute;
    top: -7%;
    left: 50%;
    transform: translateX(-50%);
  }
  h2{
    width: 100%;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 10px;
  }
  .loginbtn{
    background: none;
    border: 1px solid #00904A;
    border-color: #00904A;
    color: #00904A;
    margin: 0 auto;
    width: 232px;
    height: 64px;
  }
  .userinfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .userinfoitem{
      color: #A3A3A3;
      margin-top: 16px;
    }
  }
  .menulist{
    margin: 60px 48px 0;
    &-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 10px 30px 20px;
      margin: 30px 20px;
      border-radius: 30px;
      box-shadow: 3px 6px 10px #e8e8e8;
      .left{
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
          margin-left: 20px;
        }
      }
      img{
        width: 52px;
        height: 52px;
      }
    }
  }
}
</style>
