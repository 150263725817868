<template>
  <div class="mian">
    <img src="../assets/imgs/arrow.png" alt="" class="arrow"/>
    <p class="promise">請點擊右上角在瀏覽器打開下載APP</p>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['browser'])
  },
  created () {
    let inviteCode = this.$route.query.inviteCode
    if (this.browser !== 'wechat') {
      if (inviteCode) {
        this.$router.push(`/?inviteCode=${inviteCode}`)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>
<style scoped>
  *{
    margin:0;
    padding:0
  }
  .arrow{
    margin-left:45%;
    margin-top:1rem;
    width: 42%;
  }
  .promise{
    text-align: center;
    color: red;
    line-height: 2.5rem;
    margin-top:1rem;
  }
</style>
