<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from "./components/popup"
import { mapState } from "vuex"
export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(["loading"])
  },
  methods: {},
  created() {}
}
</script>

<style lang="less">
html,
body {
  background: #f0f1f3;
  padding: 0;
  margin: 0;
  height: 100%;
  color: #333333;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #75bb48;
  font-size: 32px;
  border-radius: 20px;
}
.w-full {
  width: 100%;
}
@font-face {
  font-family: "DIN Condensed Bold"; //自定义字体名称
  src: url("fonts/DIN\ Condensed\ Bold.ttf"); //字体包路径
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Medium"; //自定义字体名称
  src: url("fonts/Montserrat-Medium.ttf"); //字体包路径
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold"; //自定义字体名称
  src: url("fonts/Montserrat-SemiBold.ttf"); //字体包路径
  font-weight: bold;
  font-style: normal;
}
</style>
