<template>
  <page headColor="#fff">
    <template slot="head">
      <img class="page-head-back" src="../../assets/imgs/icon_back_color.png" alt="" @click="onBack">
      <img class="logo" :src="headLogo && headLogo.blackLogoUrl" alt="" />
      <div class="righticon">
      </div>
    </template>
    <div class="wallet-in-warp">
      <div class="walletin-balance">
        <p class="top"><span>{{i18n.balance}}</span><span>{{wallet.pCurrencySymbol || user.pcurrencyName}} {{wallet.pBalance || 0}}</span></p>
        <p class="bottom">{{$t('mine.deposit')}}:{{wallet ? wallet.pDepositAmount : user.pyajin || 0}}</p>
      </div>
      <div class="wallet-in-prices-warp">
        <div class="wallet-in-label">{{i18n.chargemoney}}</div>
        <div class="wallet-in-prices">
          <div class="wallet-in-price" v-for="(item, i) in prices" :key="i" @click="onItem(item)">
            <div :class="{'active': `${item}`===`${price}`}">{{user && user.pcurrencyName}} {{item}}</div>
          </div>
        </div>
      </div>
      <div class="wallet-form">
        <div class="wallet-label">{{i18n.price_label}}</div>
        <div class="wallet-input">
          <input type="number" v-model="price" :placeholder="i18n.price_placeholder" readonly="readonly" />
        </div>
      </div>
      <div class="walletin-btn">
        <button class="btn" @click="onSubmit">{{i18n.submit}}</button>
      </div>
    </div>
  </page>
</template>

<script>
import { WECHAT_MP_RECHARGE, WALLET_LIST } from '../../apis/payment.js'
import { mapState } from 'vuex'
import wxMixin from '../../mixins/wx.js'
export default {
  computed: {
    ...mapState(['user', 'browser', 'headLogo', 'agentId']),
    i18n () {
      return this.$t('wallet_in')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  mixins: [wxMixin],
  data () {
    return {
      price: 10,
      prices: [10, 20, 50, 100, 200, 500],
      wallet: {}
    }
  },
  methods: {
    onBack () {
      this.$router.go(-1)
    },
    onItem (price) {
      this.price = price
    },
    getWalletist () {
      this.$loading(true)
      this.$get(
        WALLET_LIST,
        {
          agentId: this.agentId
        },
        resp => {
          this.$loading(false)
          console.log('Walletist', resp)
          if (resp && resp.data && resp.data.length > 0) {
            this.wallet = resp.data[0]
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletist()
          })
          console.log(error)
        }
      )
    },
    onSubmit (event) {
      const vm = this
      if (this.browser === 'wechat') {
        this.$loading(true)
        console.log('event', event)
        this.$post(
          WECHAT_MP_RECHARGE,
          {
            amount: this.price,
            formId: event.detail.formId || ''
          },
          resp => {
            this.$loading(false)
            console.log('wechat recharge', resp)
            const data = resp.data
            wx.chooseWXPay({
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: data.signType,
              paySign: data.paySign,
              success: function (res) {
                vm.$confirm({
                  title: vm.i18nMsg.title,
                  content: vm.i18nMsg.success_payment,
                  showCancel: false,
                  confirmText: vm.i18nMsg.confirm
                })
                vm.$store.commit('user', {
                  ...vm.user,
                  pbalance: vm.user.pbalance + vm.price
                })
              },
              fail: function (res) {
                console.log('wxpay fail', res)
                vm.$confirm({
                  title: vm.i18nMsg.title,
                  content: JSON.stringify(res),
                  showCancel: false,
                  confirmText: vm.i18nMsg.confirm
                })
                vm.$toast(res.errMsg)
              }
            })
          },
          error => {
            console.log('wechat pre pay error', error)
            vm.$loading(false)
            vm.$toast(error)
          }
        )
      } else {
        this.$router.push(`/confirm?price=${this.price}&payType=CZ`)
      }
    }
  },
  created () {
    if (this.browser === 'wechat') {
      this.loadWXScript(['chooseWXPay'])
    }
    this.getWalletist()
  }
}
</script>

<style lang="less">
.wallet-form{
  background: #FFFFFF;
  padding: 40px 40px 0;
  .wallet-label{
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .wallet-input{
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-size: 56px;
    font-weight: 500;
    color: #333333;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    input{
      width: 100%;
      height: 68px;
      line-height: 68px;
      padding: 0 20px;
      color: #333333;
      font-size: 64px;
      border: none;
      outline: none;
    }
  }
}
.walletin-balance{
  background: #00904A;
  border-radius: 30px;
  width: 80%;
  margin: 60px auto 0;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  padding: 10px 40px;
  .top{
    margin: 20px 0 20px;
    font-size: 36px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom{
    margin: 0;
    font-size: 24px;
    text-align: end;
  }
}
.wallet-in-prices-warp{
  margin-top: 40px;
  padding: 0 40px;
  font-size: 32px;
  font-weight: 400;
  .wallet-in-label{
    color: #000;
    font-weight: 500;
    font-size: 28px;
  }
  .wallet-in-prices{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    .wallet-in-price{
      padding: 20px 17px 0 0;
      max-width: 30%;
      color: #00904A;
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      & div.active{
        background: #00904A;
        color: #FFFFFF;
      }
    }
  }
}
.wallet-in-price:nth-of-type(3n+0) {
  padding-right: 0;
}
.wallet-in-price div{
  width: 208px;
  height: 100px;
  line-height: 100px;
  border-radius: 10px;
  border: 1Px solid #00904A;
}
.walletin-btn {
  padding: 55px;
  button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
</style>
