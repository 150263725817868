<template>
  <page back>
    <div class="mainbgc"></div>
    <div class="confirm-warp">
      <!-- <div class="confirm-info">
        <div class="confirm-title">{{i18n.pay_label}}</div>
        <div class="confirm-price">{{currency}}{{price}}</div>
        <div class="confirm-icon">
          <img src="../../assets/imgs/icon_tips.png" />
          <span>{{i18n.price_tips}}</span>
        </div>
      </div> -->
      <div class="confirm-payment-label">
        <span>{{ i18n.pay_type }}</span>
      </div>
      <div class="confirm-payment">
        <div class="addcard" @click="saveCard()">
          <div class="left">
            <img src="../../assets/imgs/icon_coupon.png" alt="" />
            <span class="addcard-msg">{{ i18n.addcard }}</span>
          </div>
          <img src="../../assets/imgs/icon_more.png" alt="" />
        </div>
        <div class="confirm-scroll-y">
          <div
            class="confirm-card-list"
            :class="{ selected: payMode.pId === item.pId }"
            v-for="item in cardList"
            :key="item.pId"
            @click="payMode = item"
          >
            <p class="value">
              <span class="pBrand">{{ item.pBrand }}</span>
              <span>****{{ item.pCardnumber }}</span>
            </p>
            <p class="date">{{ item.pExpirationdate }}</p>
          </div>
        </div>
        <!-- <div class="confirm-payment-item" v-for="item in payMethodList" :key="item.pId" @click="payMode=item">
          <div class="confirm-payment-item-info">
            <img src="../../assets/imgs/wallet.png" />
            <div class="content">
              <div class="title">{{ item.pPaymentType }}</div>
            </div>
          </div>
          <div class="confirm-payment-item-checked">
            <img v-if="payMode.pPaymentType===item.pPaymentType" src="../../assets/imgs/checkbox_selected.png" />
            <img v-else src="../../assets/imgs/checkbox.png" />
          </div>
        </div> -->
      </div>
      <div class="confirm-btns">
        <div class="btn" @click="onPay">
          {{ payType === "CZ" ? i18n.charge : i18n.submit }}
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import {
  USER_CARD,
  SAVE_CARD,
  PAYMODE,
  QUICK_CREATE,
  PAYPAL_PRE_PAY,
  PAY_ORDER,
  SAVEDCARD_CONFIRM,
  WALLET_RECHARGE
} from "../../apis/payment.js"

export default {
  components: {},
  computed: {
    ...mapState(["system", "cabinet", "user", "qrcode"]),
    i18n() {
      return this.$t("confirm")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      if (this.cabinet) {
        return this.cabinet.currencyName
      } else {
        return this.user.pcurrencyName
      }
    }
  },
  watch: {},
  data() {
    return {
      price: 0,
      cardList: [],
      payMethodList: [],
      payType: "YJ",
      payMode: {
        pPaymentType: "Quickpay"
      },
      outTradeNo: ""
    }
  },
  methods: {
    getCardList() {
      this.$loading(true)
      this.$get(
        USER_CARD,
        {},
        (resp) => {
          this.$loading(false)
          console.log("cardlist", resp)
          this.cardList = resp.data
          this.payMode = resp.data[0] || null
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getCardList()
          })
          console.log(error)
        }
      )
    },
    saveCard() {
      // this.paymethodshow = false
      // if (!this.payMode || !this.payMode.pPaymentType) {
      //   this.$toast('Please select a payment channel')
      //   return
      // }
      this.$loading(true)
      this.$post(
        SAVE_CARD,
        {
          paymentId: 2,
          continueUrl: window.location.href,
          cancelUrl: window.location.href
        },
        (resp) => {
          this.$loading(false)
          console.log("savecard", resp)
          window.location.href = resp.data.redirectUrl
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    getPayMethodList() {
      this.$get(
        PAYMODE,
        {
          id: this.qrcode
        },
        (resp) => {
          console.log("PayMethodlist", resp)
          this.payMethodList = resp.data || []
          this.payMode = resp.data[0] || null
        },
        (error) => {
          console.log(error)
        }
      )
    },
    savedCardConfirm() {
      this.$get(
        SAVEDCARD_CONFIRM,
        {
          paymentId: this.$route.query.paymentId,
          quickpayCardId: this.$route.query.quickpayCardId
        },
        (resp) => {
          console.log("PayMethodlist", resp)
        },
        (error) => {
          console.log(error)
        }
      )
    },
    onPay() {
      if (!this.payMode || !this.payMode.pCardnumber) {
        this.$toast(this.i18nMsg.addorselectcard)
        return
      }
      this.$loading(true)
      let url = ""
      let param = null
      if (this.payType === "CZ") {
        url = WALLET_RECHARGE
        param = {
          rechargeType: "balance",
          amount: this.price
        }
      } else if (this.payType === "YJ") {
        url = QUICK_CREATE
        param = {
          qrcode: this.qrcode
        }
      } else if (this.payType === "FK") {
        url = PAY_ORDER
        param = {
          outTradeNo: this.outTradeNo
        }
      } else if (this.cabinet) {
        url = `${PAYPAL_PRE_PAY}/${this.cabinet.qrcode}`
      }
      // if (this.payMode.pPaymentType) {
      //   param.paymentId = this.payMode.pId
      // }
      if (this.payMode.pCardnumber) {
        param.userCardId = this.payMode.pId
      }
      console.log("参数", param, url)
      this.$post(
        url,
        param,
        (resp) => {
          this.$loading(false)
          console.log("onPay", resp)
          if (resp.data) {
            if (resp.data.redirectUrl) {
              this.$loading(true)
              window.location.href = resp.data.redirectUrl
            } else {
              // out_trade_no: "23030308192018544147"
              // trade_no: 371505403
              this.$router.push(
                `/result?outTradeNo=${resp.data.out_trade_no}&payType=${this.payType}`
              )
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onPay()
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    }
  },
  created() {
    let price = this.$route.query.price
    this.price = price ? Number(price) : 0
    this.payType = this.$route.query.payType
    this.outTradeNo = this.$route.query.outTradeNo
    if (this.$route.query.paymentId && this.$route.query.quickpayCardId) {
      this.savedCardConfirm()
    }
    this.getCardList()
    // this.getPayMethodList()
  }
}
</script>

<style lang="less">
.selected {
  border: solid 1px #00904a;
}
.confirm-warp {
  position: absolute;
  top: 7%;
  left: 0;
  width: 100%;
  min-height: 40%;
  overflow-y: scroll;
  background: #fff;
}
.confirm-info {
  background: #ffffff;
  text-align: center;
  padding: 40px;
}
.confirm-title {
  font-size: 36px;
}
.confirm-price {
  font-size: 80px;
  color: #fc0d1b;
}
.confirm-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  & span {
    text-align: left;
    font-size: 28px;
    color: #999999;
  }
}
.confirm-payment {
  background: #ffffff;
  padding: 30px;
  .addcard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  .confirm-scroll-y {
    max-height: 760px;
    margin: 20px 0 0;
    overflow-y: scroll;
  }
  .confirm-card-list {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    padding: 10px 20px;
    margin: 20px 0;
    p {
      margin: 15px 0;
    }
    .value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #00904a;
      font-weight: 500;
      .pBrand {
        font-size: 40px;
      }
    }
    .date {
      color: #999;
      text-align: end;
    }
  }
  &-label {
    padding: 10px 20px;
    font-size: 28px;
    color: #999999;
    background: #f7f7f7;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 20px;
    // border-bottom: 1px solid #dddddd;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    margin: 20px 0;
    & img {
      width: 40px;
      height: 40px;
    }
    &-info {
      display: flex;
      align-items: center;
      flex: 1;
      & .content {
        padding-left: 10px;
      }
      & .sub_title {
        font-size: 28px;
        color: #fec348;
      }
    }
  }
}
.confirm-btns {
  padding: 20px 40px 20px;
}
.confirm-btns .btn {
  margin-top: 20px;
}
.confirm-btns button.btn {
  background-color: #65b74e;
}
</style>
