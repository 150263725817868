<template>
  <page back>
    <div class="mainbgc"></div>
    <div class="container">
      <div class="borrow-body">
        <div class="top">
          <h2>Fuyong Factory</h2>
          <span class="price">$2/ 60 min</span>
          <span class="priceline">Original price: $4/60 min</span>
        </div>
        <div class="tipcontent">
          <p>
            <span class="greendot"></span>
            <span class="dotcontent">Free within 5 minutes (after that, 5 minutes will be included in the billing time)</span>
          </p>
          <p>
            <span class="greendot"></span>
            <span class="dotcontent">24 hours capped at $20</span>
          </p>
          <p>
            <span class="greendot"></span>
            <span class="dotcontent">Maximum cap of $99, deposit of $99, support no-deposit rental</span>
          </p>
        </div>
      </div>
      <span class="btn rentbtn">Rent now</span>
      <div class="agreement">
        <img v-if="userAgreement" src="../../assets/imgs/icon_checked.png" alt="" @click="userAgreement = false">
        <img v-else src="../../assets/imgs/icon_unchecked.png" alt="" @click="userAgreement = true">
        <span>Click to agree to the "Delegated Debit Authorization Form", "User Agreement", "Top-up Agreement</span>
      </div>
      <div class="agreement">
        <img v-if="privacyAgreement" src="../../assets/imgs/icon_checked.png" alt="" @click="privacyAgreement = false">
        <img v-else src="../../assets/imgs/icon_unchecked.png" alt="" @click="privacyAgreement = true">
        <span>Click to agree to the Privacy Agreement</span>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapState([
      'qrcode',
      'zhcode',
      'cabinet',
      'browser',
      'user',
      'system'
    ]),
    i18n () {
      return this.$t('home')
    },
    i18nMsg () {
      return this.$t('msg')
    }
  },
  data () {
    return {
      isScan: false,
      userAgreement: false,
      privacyAgreement: false
    }
  },
  methods: {
    goRouter (path) {
      this.$router.push(path)
    },
    // 頭部主頁圖標按鈕
    goHome () {
      this.$store.commit('qrcode', '')
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.container{
  position: absolute;
  top: 10%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  .rentbtn{
    width: 85%;
    margin: 100px auto 20px;
  }
  .agreement{
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: safe;
    margin: 20px auto 0;
    font-size: 24px;
    color: #999;
    img{
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
}
.borrow-body{
  background: #fff;
  width: 80%;
  margin: 40px auto 0;
  border-radius: 60px;
  padding: 30px 30px;
  box-shadow: 3px 6px 10px #e8e8e8;
  .top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  h2{
    margin: 20px 0;
    font-size: 40px;
  }
  .price{
    display: block;
    width: 240px;
    height: 64px;
    font-size: 34px;
    background: #00904A;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-top: 30px;
  }
  .priceline{
    text-decoration: line-through;
    color: #8C8C98;
    margin-top: 20px;
    font-size: 24px;
  }
  .tipcontent{
    margin-top: 10px;
    p{
      .greendot{
        display: inline-block;
        background: #00904A;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        margin-right: 10px;
      }
    }
  }
}
</style>
